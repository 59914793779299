<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }}  general request category
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="closeDialog">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Title" v-model="category.title" outlined dense>
                              <template v-slot:label>
                                Title
                                <span class="text-danger">*</span>
                              </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.category.title.$error">This information is required</span>
                        </v-col>
                        <v-col cols="12">
                            <v-select label="Type" :items="generalRequestCategories" item-text="name" item-value="value" v-model="category.type" outlined dense>
                              <template v-slot:label>
                                Type
                                <span class="text-danger">*</span>
                              </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.category.type.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" v-if="currentUser && currentUser.type == 'admin' && category.applicable_users && category.applicable_users.length > 0">

                          <ul class="mb-3">
                            <li class="mx-10" v-for="(item, index) in category.applicable_users" :key="index">
                              <v-chip class="my-1"  small >{{item || 'N/A'}}</v-chip>
                            </li>
                          </ul>
                          <ul class="mb-3" v-if="category.applicable_users && category.applicable_users.length == 0">
                            <span class="text-center">No one applicable users is selected</span>
                          </ul>

                        </v-col>
                        <v-col cols="12">

                          <label>Applicable users (Supervisors) <span class="text-danger">*</span> </label>
                            <v-autocomplete
                                v-model="user_ids"
                                :items="items"
                                hide-selected
                                item-text="display_text"
                                item-value="id"
                                :search-input.sync="searchUsers"
                                @input="category.user_id = $event !== null ? $event : ''"
                                :loading="isLoading"
                                multiple
                                chips
                                small-chips
                                deletable-chips
                                clearable
                                outlined
                                dense
                            >
                              <template v-slot:label>
                                Search by name, email or CRN <span class="text-danger">*</span>
                              </template>
                              <template v-slot:no-data>
                                <div class="no-data-auto-complete text-center">No data available </div>
                              </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.category.applicable_users_ids.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                          <label for="description">Description</label>
                          <ckeditor id="description" v-model="category.description"
                                    :config="editorConfig"></ckeditor>
                          <span class="text-danger" v-if="$v.category.description.$error">This information is required</span>

                        </v-col>
                        <v-col cols="3">
                            <v-switch v-model="category.is_active" label="Active"></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="closeDialog" class="text-gray btn btn-standard cancel-btn">
                                    Cancel
                                </v-btn>
                                <v-btn v-if="checkIsAccessible('general-request-category', 'create') || checkIsAccessible('general-request-category', 'edit')" depressed :loading="isBusy" @click="createOrUpdate"
                                       class="text-white ml-2 btn btn-primary ">
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import GeneralRequestCategoryService from "@/core/services/general-request/category/GeneralRequestCategoryService";

const user = new UserService();
const requestCategory = new GeneralRequestCategoryService();

export default {
  name: 'create-request-category',
  validations: {
      category: {
        title: {required},
        type: {required},
        applicable_users_ids: {required},
        description: {required},
      }
  },
  data() {
      return {
        users: [],
        user_ids: [],
        dialog: false,
        edit: false,
        isBusy: false,
        isLoading: false,
        searchUsers: null,
        editorConfig: {
          versionCheck: false,
            toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
        },
        category: {
            title: null,
            description: null,
            applicable_users_ids: [],
            is_active: true,
        },
        generalRequestCategories: [
          {name: "Clubs meeting and room request", value: 'club_meeting'},
          {name: "Change course", value: 'change_course'},
          {name: "Practical leave", value: 'practical_leave'},
          {name: "Transportation leave", value: 'transportation_leave'},
          {name: "Late request", value: 'late_request'},
          {name: "Uniform request", value: 'uniform_request'},
          {name: "Other", value: 'other'},
        ],
      };
  },
  mounted() {
    if(this.currentUser && this.currentUser.type != 'admin') {
      this.category.applicable_users_ids.push(this.currentUser.id);
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    items() {
      return this.users.map((user) => {
        user.display_text =
            user.full_name +
            " | " +
            user.personal_id +
            " | " +
            user.email
        return user;
      });
    }
  },
  watch: {
    searchUsers(val) {
      if(val) {
        this.isLoading = true;
        const types = ['staff', 'coordinator'];
        let data = {
          val: val,
          users_ids: this.category.applicable_users_ids,
          types: types
        };
        user.getByTypes(data).then((response) => {
          if(response.data.users) {
            this.users = response.data.users;
          } else {
            this.users = [];
          }
        }).catch(() => {}).finally(() => (this.isLoading = false));
      }
    },
  },
  methods: {
    createDialog() {
      this.resetForm()
      this.dialog = true;
    },

   editDialog(item) {
      this.resetForm()
      this.category = item
      this.dialog = true;
      this.edit = true;
    },

    closeDialog() {
        this.dialog = false;
        this.resetForm();
    },

    createOrUpdate() {
      this.category.applicable_users_ids = [...this.user_ids, ...this.category.applicable_users_ids];
      this.$v.category.$touch();
      if (this.$v.category.$error) {
          setTimeout(() => {
              this.$v.category.$reset();
          }, 3000);
      } else {
          if (this.edit) {
              this.__update();
          } else {
              this.__create();
          }
      }
    },
    __update(fd) {
        this.isBusy = true;
        requestCategory
            .update(this.category.id, this.category)
            .then(response => {
                if(response.data.status == "OK") {
                  this.isBusy = false;
                  this.$snotify.success("Information updated");
                  this.$emit("refresh");
                  this.closeDialog();
                }
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
    },


    __create(fd) {
        this.isBusy = true;
        requestCategory
            .store( this.category)
            .then((response) => {
                if(response.data.status == "OK") {
                  this.isBusy = false;
                  this.$emit("refresh");
                  this.$snotify.success("Information added");
                  this.closeDialog();
                }
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
    },

    resetForm() {
        this.$v.category.$reset();
        this.edit = false;
        this.isLoading = false;
        this.isBusy = false;
        this.user_ids = [];
        this.users = [];
        this.searchUsers = null;
        this.category = {
            title: null,
            description: null,
            applicable_users_ids: [],
            is_active: true,
        };
    },
  }
};
</script>

<style scoped>
.v-chip--select {
  margin: 2px 4px 2px 4px !important
}
</style>
