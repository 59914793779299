<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage general request category</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ General request category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('general-request-category', 'create')"
                    @click="__add()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    general request category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    v-on:keyup.enter="getAllRequestCategory"
                    outlined
                    v-model="search.title"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="status"
                    item-text="title"
                    v-on:keyup.enter="getAllRequestCategory"
                    item-value="value"
                    v-model="search.is_active"
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    :loading="loading"
                    @click="getAllRequestCategory"
                    class="btn btn-primary btn-search"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Applicable users</th>
                    <th class="px-3 text-center">Action</th>
                  </tr>
                </thead>

                <tr
                  v-show="requestCategories.length > 0"
                  v-for="(category, index) of requestCategories"
                  :key="index"
                >
                  <td  class="px-3 wrap-column">
                    <a href="#" @click="__edit(category)" class="mr-2">
                      {{ category.title }}</a
                    >
                    <i
                      class="fas fa-circle"
                      :class="category.is_active ? 'dot-active' : 'dot-inactive'"
                    ></i>
                  </td>
                  <td class="px-3 py-4 wrap-column">
                    <ul class="mb-3" v-if="category.applicable_users.length > 0">
                      <li class="mx-10" v-for="(item, index) in category.applicable_users" :key="index">
                        <v-chip class="my-1" close small @click:close="removeApplicableUsers(category, index)">{{item || 'N/A'}}</v-chip>
                      </li>
                    </ul>
                    <ul class="mb-3" v-if="category.applicable_users && category.applicable_users.length == 0">
                      <span class="text-center">No one applicable users is selected</span>
                    </ul>
                  </td>

                  <td class="pr-0 text-center">
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i> </span
                        ></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            v-if="checkIsAccessible('general-request-category', 'edit')"
                            href="#"
                            class="navi-link"
                            @click="__edit(category)"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            v-if="checkIsAccessible('general-request-category', 'delete')"
                            href="#"
                            class="navi-link"
                            @click="__delete(category.id)"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </td>
                </tr>
                <tr v-if="requestCategories.length == 0">
                  <td colspan="4" class="text-center">
                    <strong>Data not available</strong>
                  </td>
                </tr>
              </table>
              <b-pagination
                v-if="requestCategories.length > 0"
                class="pull-right mt-7"
                @input="getAllRequestCategory"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update
      ref="category"
      @refresh="getAllRequestCategory"
    ></create-and-update>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import GeneralRequestCategoryService from "@/core/services/general-request/category/GeneralRequestCategoryService";

const category = new GeneralRequestCategoryService();
export default {
  name: "general-request-category",
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      requestCategories: [],
      loading: false,
      page: null,
      perPage: null,
      total: null,
      search: {
        title: "",
        is_active: "1",
      },
      status: [
        { title: "Active", value: "1" },
        { title: "Inactive", value: "0" },
      ],
    };
  },
  mounted() {
    this.getAllRequestCategory();
  },
  computed: {},
  methods: {

    getAllRequestCategory() {
      this.loading = true;
      category
        .paginate(this.search)
        .then((response) => {
          this.requestCategories = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    __add() {
      this.$refs["category"].createDialog();
    },
    __edit(item) {
      this.$refs["category"].editDialog(item);
    },

    __delete(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            category
              .delete(id)
              .then((response) => {
                this.$snotify.success("Successfully Deleted");
                this.getAllRequestCategory();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },

    removeApplicableUsers(item, index) {

      this.$confirm({
        message: `Are you sure you want to remove this user?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            item.applicable_users_ids.splice(index, 1);
            category
                .update(item.id, item)
                .then((response) => {
                  if(response.data.status == "OK") {
                    this.getAllRequestCategory()
                    this.$snotify.success("Remove successfully");
                  }
                })
                .catch(() => {
                  this.$snotify.error("Something Went Wrong");
                });
          }
        }
      });
    },
  },
};
</script>
<style scoped></style>
