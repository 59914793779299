import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;

export default class GeneralRequestCategoryService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/general-request-category`;
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }
    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getAllRequestCategory() {
        let url = `${this.#api}/get/all`
        return apiService.get(url);
    }

    getAllRequestCategoryMenu() {
        let url = `${this.#api}/get/menu`
        return apiService.get(url);
    }
}
